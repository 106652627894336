import { useState } from "react";
import { findProject } from "../../assets/data/projects";
import { useParams } from "react-router-dom";
import './project.scss'

export default function Project() {
    let { id } = useParams();

    const [project] = useState(findProject(id));

    return (
        <div className="project-wrapper">
            <div className="project-page-image" style={{backgroundImage: `url(${project.image})`}}></div>
            <div className="project-info">
                <h1>{project.title}</h1>
                <h3 className="project-tech">{project.tech}</h3>
                <span>Link to project:</span><a href={project.link}>{project.link}</a>
                <span>Link to project git repository:</span><a href={project.gitLink}>{project.gitLink}</a>
                <p className="project-description">{project.intro}</p>
                <br/>
                <p className="project-description">{project.line1}</p>
                <br/>
                <p className="project-description">{project.line2}</p>
            </div>
        </div>
    );
}
