import './ProjectTile.scss';
import { Link } from "react-router-dom";

export default function Card({ project }) {
    const { id, title, intro, image, tech } = project;

    return (
        <div className="projects-tile-wrapper">
            <div className="projects-tile">
                <h2>{title}</h2>
                <Link to={`/projects/${id}`} className='projects-image'>
                    <img src={image} alt="project-banner"></img>
                </Link>
                <p>{tech}</p>
                <br/>
                <p className="project-short-description">{intro}</p>
                <Link to={`/projects/${id}`}>
                    <button className="project-button">LEARN MORE</button>
                </Link>
            </div>
        </div>
    );
}