import norths from '../../assets/images/norths.jpg';
import './home.scss';

export default function HomePage() {
    return (
        <div className="home-wrapper">
            <div className="intro-section">
                <h1 className="intro-header">
                    Hello,
                <br/>
                    I am Jered
                </h1>
                <p className="intro-text">I enjoy coding. Here you can find out more about me and what I have worked on.</p>
            </div>

            <div className="overview-section">
                <h1 className="overview-header">Web Development</h1>
                <p className="overview-paragraph">
                    A bit about me, although I do enjoy the outdoors,
                    hanging out with my wife and friends playing card games or board games is my comfort zone
                    and of course, I love to code!
                </p>
                <img className="overview-image" src={norths} alt="norths"/>
                <p className="overview-paragraph-summary">
                    In my career thus far I have worked as a full stack developer with most of my projects leaning more into front end work.
                    <br/><br/>
                    I've worked most of my career using PHP within the Laravel framework building out blade pages for ecommerce sites.
                    <br/><br/>
                    I've also had the opportunity to work with Elasticsearch, taking products from a Mongo Database to the front end where users can search for and accurately find what they are looking for
                    <br/><br/>
                    This only gives a brief overview of what I've done, if you would like to learn more please feel free to reach out!
                </p>
            </div>

        </div>
    );
}