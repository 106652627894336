import { useState } from "react";
import './projects.scss'
import ProjectTile from "../../components/ProjectTile";
import { getProjects } from "../../assets/data/projects";

export default function ProjectsPage() {
    const [projects] = useState(getProjects());

    return (
        <div className="projects-wrapper">
            <h1 className="projects-header">PROJECTS</h1>

            <div className="projects-intro-section">
                <h2 className="projects-intro-header">What I have done!</h2>
                <p className="projects-intro-paragraph">
                    I am currently working on sharpening my skill set and broadening my tool set to push myself more into a front-end focus.
                </p>
            </div>
            <hr />
            {projects.map((project) => {
                return <ProjectTile project={project} key={project.id}></ProjectTile>
            })}
        </div>
    );
}