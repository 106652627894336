import './skills.scss'

export default function SkillsPage() {
    return (
        <div className="skills-wrapper">
            <h1 className="skills-header">SKILLS</h1>

            <div className="skills-intro-section">
                <h2 className="skills-intro-header">What Can I do?</h2>
                <p className="skills-intro-paragraph">
                    I have worked for over 5 years as a full stack Software Engineer with a focus on Fronte End Development,
                    Professional experience with Vue, React, JavaScript (ES6), HTML, CSS, Node, PHP/Laravel, and WAMP.
                    <br />
                    I have experience building and maintaining features for ecommerce websites.
                </p>
            </div>
            <hr />
            <div className="skills-technology-section">
                <ul className="skills-list">
                    <li>Vue | React | HTML | CSS</li>
                    <li>JavaScript | JQuery</li>
                    <li>mySQL | MongoDB</li>
                    <li>REST</li>
                    <li>GitHub | GitLab</li>
                </ul>
            </div>
            <hr />
            <div className="skills-history-section">
                <h3>Previous Job History</h3>
                <h4>G.O. Green Enterprises Inc.</h4>
                <p>Linux | WAMP | PHP | Laravel | HTML | CSS | Jquery | MongoDB | mySQL </p>
                <br/>
                <p>G.O. Green manages several different websites the primary site being Plumbersstock.com, an ecommerce site for plumbing supplies. Other sites include Mowro.com, Adamsandco.net.</p>
                <br/>
                <p>While I was at G.O. Green I have:</p>
                <br/>
                <ul>
                    <li>Improved search functinality on the sites using Elasticsearch through better filters, more relevant search and auto-suggest functionality</li>
                    <li>Implementing and working with a third-party search solution</li>
                    <li>Generating and automationg sending a full product feed to third-party partners</li>
                    <li>Creating pages and frontend components for company websites</li>
                    <li>Worked on rebuilding/refactoring the front-end of Mowro.com from wireframes</li>
                </ul>
            </div>
            <hr />
            <div className="skills-history-section">
                <h4>Card Champs</h4>
                <p>Vue.js | Quasar | Node | Express | Javascript | Feathers.js | MongoDB </p>
                <br/>               
                <p>At Card Champs I worked on the Card Champs application which is a social media/marketplace for sports cards collectors.</p>
                <br/>               
                <p>While I was at Card Champs I have:</p>
                <br/>              
                <ul>
                    <li>Created front-end pages and Vue components for the application</li>
                    <li>Created a dialogue to view and manage the users you are following and view who is following you</li>
                    <li>Created functionality with pages and components to review other users, view the reviews of other users, and manage the reviews that you have sent</li>
                </ul>
            </div>
        </div>
    );
}