import { Link, Outlet } from "react-router-dom";
import pdf from '../assets/pdf/jered-north-resume.pdf'

export default function MainLayout() {
    return (
        <div className="main-layout">
            <nav className="header-nav">
                <h1>
                    <Link to="/">Home</Link>
                </h1>
                <h1>
                    <Link to="/skills">Skills</Link>
                </h1>
                <h1>
                    <Link to="/projects">Projects</Link>
                </h1>
            </nav>
            <button className="menu-button">Menu</button>
            <div className="+">
                <Outlet />
                <footer className="main-footer">
                    <div className="main-email">
                        <h3>Email</h3>
                        <p>jered.north@gmail.com</p>
                    </div>
                    <div className="main-socials">
                        <h3>Social</h3>
                        <ul>
                            <li>
                                <a title="Resume" href={pdf}
                                    className="icon alt fa-twitter"><span className="label">Resume</span></a>
                            </li>
                            <li>
                                <a title="Github" href="https://github.com/JeredNorth"
                                    className="icon alt fa-twitter"><span className="label">Github</span></a>
                            </li>
                            <li>
                                <a title="Linkedin" href="https://www.linkedin.com/in/jered-north/"
                                    className="icon alt fa-twitter"><span className="label">Linkedin</span></a>
                            </li>
                        </ul>
                    </div>
                </footer>
            </div>
        </div>
    );
}