import gitHubBanner from "../../assets/images/github-banner.png";
import vueBanner from "../../assets/images/vue-banner.webp";
import plumbersstockBanner from "../../assets/images/plumbersstock-banner.png";

const projects = [
  {
    id: 1,
    title: 'Plumbersstock',
    intro: 'Plumbersstock.com, an ecommerce site for plumbing supplies, is the main site I worked on while working at G.O. Green Enterprises Inc.',
    tech: 'Linux | WAMP | PHP | Laravel | HTML | CSS | Jquery ',
    link: 'https://www.plumbersstock.com/',
    gitLink: '',
    image: plumbersstockBanner,
    line1: 'My main project was working on the search aspect of Plumbersstock.com using Elasticsearch to set up an elasticsearch index that took all of our products from a MongoDB collection then through API calls to get the data from the index and put it through filters and checks to display to the users the most relevant search possible.',
    line2: 'I set up auto-complete for the search through AJAX calls to the Elasticsearch index and needed to create the dialogue box that appears while you are typing.',
  },
  {
    id: 2,
    title: 'Vue Project',
    intro: 'In this Project I created pages and vue components for those pages',
    tech: 'Vue | JavaScript | HTML | CSS | Quasar',
    link: 'https://jn-vue-components.netlify.app',
    gitLink: 'https://github.com/JeredNorth/vue-component-learning',
    image: vueBanner,
    line1: "I created a dialog that takes in either followers or following and displays the users in those collections..",
    line2: "I created a component to display the reviews of a user that goes on the user's profile page. I then also create a page to be able to view all of the reviews a user has recieved.",
  },
  {
    id: 3,
    title: 'Movie Database Demo',
    intro: 'A small coding exercise that I made to connect to an api and return data in a nice way to the user',
    tech: 'PHP | Laravel | HTML | CSS',
    link: '',
    gitLink: 'https://github.com/JeredNorth/moviedb-search',
    image: gitHubBanner,
    line1: 'I created this project as a simple exercise to connect to an API and serve up the data to the front end and display it in a user friendly way.',
    line2: "There's a home page with just an input field and a button where you type in a movie title and it sends you to a page where information from that movie is shown, the data shown is the title, runtime, first 10 actors and their characters, and a brief summary of the movie",
  }
];

export function getProjects() {
  return projects;
}

export function findProject(id) {
  return projects.find(project => project.id === +id);
}