import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import HomePage from "./pages/home/home";
import MainLayout from "./layouts/main-layout";
import SkillsPage from "./pages/skills/skills";
import ProjectsPage from "./pages/projects/projects";
import Project from "./pages/project/project";

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route element={<App />}>
          <Route element={<MainLayout/>}>
            <Route path="/" element={<HomePage />} />
            <Route path="/skills" element={<SkillsPage />} />
            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/projects/:id" element={<Project />} />
          </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);